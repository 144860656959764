@import "../../global.scss";

.auth{
  @include flexCenterCenterDirection(row);
  width: 100%;
  height: 100vh;
  background-color: $primary-light;

  &__banner{
    width: 40%;
    height: 100vh;
    background-color: $secondary-dark;
  }

  &__form{
    @include flexCenterCenter();

    width: 60%;
    height: 100vh;

    &-box{
      @include flexCenterSBColumn();
      width: 380px;
      gap: 25px;
      background-color: $primary;
      border-radius: 16px;
      padding: 20px;
    }

    &-title{
      font-size: 22px;
      font-weight: 600;
      color: $secondary-dark;
    }

    &-subText{
      cursor: pointer;
      width: 100%;
      text-align: left;
      font-size: 14px;
    }
    &-subText:hover{
      color: $secondary-light;
      text-decoration: underline;
    }
  }
}