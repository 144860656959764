@import "../../global.scss";

.trans{
  @include flexCenterCenter();

  width: 100%;
  height: calc(100vh - 72px);

  &__title{
    font-size: 40px;
    font-weight: 600;
    color: $secondary-dark;
  }
}