@import "../../global.scss";

.nav{
  width: 100%;
  // background-color: $primary-light;

  &__topbar{
    @include flexCenter_Direction(row);
    height: 70px;
    width: 100%;
    background-color: white;
    border-bottom: solid 1px $primary-light;
    position: absolute;
    top: 0px;
    z-index: 5;
    padding-left: 20px;
    padding-right: 20px;

    &-left{
      margin-left: -20px;
      width: 70px;
      height: 70px;
      // background-color: red;
      border-right: solid 1px $primary-light;
    }

    &-right{
      margin-left: 20px;
      font-weight: 500;
    }
  }


  &__content{
    @include flex_CenterDirection(row);
    margin-top: 70px;


    &-left{
      @include flexCenterSBDirection(column);
      width: 70px;
      border-right: solid 1px $primary-light;
      background-color: white;
      min-height: calc(100vh - 70px);
      height: 100%;
      padding: 10px;

      &-top{
        
      }

      &-bottom{

      }

      &-item{
        @include flexCenterCenterDirection(column);
        padding: 15px 0px;
        margin: auto;
        border-radius: 12px;
        margin-bottom: 10px;
        gap: 5px;

        cursor: pointer;

        font-size: 13px;
        font-weight: 600;
        color: $secondary-dark;

        transition: .2s all;

        text-align: center;

        // &:hover{
        //   background-color: $primary-dark;
      
        // }
      }

      &-selected{
        @include flexCenterCenterDirection(column);
        padding: 15px 0px;
        margin: auto;
        margin-bottom: 10px;
        border-radius: 12px;
        gap: 5px;

        cursor: pointer;

        font-size: 13px;
        font-weight: 600;
        color: $primary-dark;
        // background-color: $secondary-light;
        transition: .2s all;

        text-align: center;

        // &:hover{
        //   background-color: $secondary-light;
        //   color: $primary-light;
        // }
      }
    }

    &-right{
      width: calc(100% - 70px);
      min-height: calc(100vh - 70px);
      height: calc(100vh - 100px);
      padding: 15px;
      // padding: 15px 15vw 10px 15vw;
      // padding-bottom: 10px;
      background-color: $white;

      border-radius: 16px 0px 0px 0px;

      overflow: auto;
    }
  }
}
