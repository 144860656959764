@import "../../global.scss";

.footer__main{
  @include flexCenterSBColumn();
  
  background-color: $secondary-dark;
  width: 100%;
  height: 500px;
  padding: 30px 0px 20px 0px;

  &-heading{
    width: 80%;
    height: 70px;
    font-size: 52px;
    font-weight: 600;
    color: $primary-light;
  }

  &-sub{
    text-align: center;
    color: $primary-light;
    font-size: 18px;
    font-weight: 500;
  }
}