@import "../../global.scss";

.vcn{
  border-radius: 12px;
  background-color: #f8f8f8;
  margin-top: 20px;
}

.vcn__row{
  cursor: pointer;

}

.vcn__row:hover{
  background-color: #f1f1f1;
}

.vcn__row--selected{
  background-color: $primary-light;
}

.card{
  width: 100%;
  margin: auto;
  margin-top: 30px;
  max-width: 400px;
  height: 220px;
  padding: 15px;
  border-radius: 12px;
  background-color: #f1f1f1;

  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
}

.card__top{
  font-size: 22px;
  font-weight: 500;
}

.card__number{
  font-size: 26px;
  font-weight: 600;
}


.card__expiry{
  font-size: 20px;
  font-weight: 500;
}

.card__cvv{
  font-size: 20px;
  font-weight: 500;
}