@import "../../global.scss";

.topbar{
  @include flexCenterSBDirection(row);
  width: 100%;
  height: 72px;
  background-color: $primary-light;
  // background-color: #f7f7f7;
  // box-shadow: $primary-light 0px 5px 15px 0px;
  border-radius: 12px;
  padding: 10px 10px 10px 15px;

  &__title{
    font-size: 24px;
    font-weight: 600;
    color: $secondary-dark;
  }
}