@import "../../global.scss";

.main{
  width: 100%;
  height: 100vh;
  background-color: $primary-light;
  padding-top: 60px;

  overflow: hidden;

  &__content{
    @include flexCenterCenterDirection(column);
    position: absolute;
    top: 0;
    z-index: 10;
    width: 100%;
    height: 100vh;

    &--title{
      text-align: center;
      font-size: 120px;
      font-weight: 700;
      color: $secondary-dark;
      text-shadow: 1px 1px 100px $primary-dark;
      margin-bottom: 60px;
    }
  }


}